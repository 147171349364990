import React from "react";
import LayoutSub from "../components/layout-sub";
import SEO from "../components/seo";
import Sidebar from "../components/sidebar";
import { Link } from "gatsby"

export default ({data}) => (
  <LayoutSub title="404">
    <SEO
      title="404"
    />
    
    <article id="main-article" className="container flex flex-wrap items-start lg:flex-no-wrap mx-auto p-4 md:p-8 xl:p-16">
      <div role="main" className="article w-full lg:w-2/3 lg:pr-20 xl:pr-40">
        <h2>Sidan kan kunde inte hittas</h2>
        <Link to="/">
          Till startsidan
        </Link>
      </div>

      <Sidebar data={data.tjanster} />
    </article>
  </LayoutSub>  
)


export const query = graphql`
{
  tjanster: allMarkdownRemark(filter: {frontmatter: {type: {eq: "service"}}}, sort: {fields: frontmatter___order}) {
    edges {
      node {
        id
        frontmatter {
          name
          slug
          service_excerpt
        }
      }
    }
  }
}
`
